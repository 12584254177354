import React from 'react';
import ListaTipoPlan from "./ListaTipoPlan";
import TextosAsesoriaPlus from './TextosAsesoriaPlus'; // Importa el componente de textos
import '../stylesheets/AsesoriaPlusDetalle.css'; 
import '../stylesheets/BotonCotizar.css';
import { useNavigate } from 'react-router-dom';
import { useScreen } from './ScreenContext';


function AsesoriaPlusDetalle ({idTipoSeguro, tituloasesoriaplus, EmpCod, ProTipCod, 
  TabCod, TabCodCel, AseCod, handleClick, setBodyCotizacion}) {

    console.log('AsesoriaPlusDetalle.js' , 'IdSeguro: ', idTipoSeguro);

  const navigate = useNavigate();
  const { currentScreen, setScreen } = useScreen(); 
  const Cotizador = () => {
    setScreen('Cotizador');
    navigate('/cotizador');
  }
  
  const getVideoUrl = () => {
    switch (tituloasesoriaplus) {
      case 'INTEGRAL FOOD TRUCK':
        return 'https://www.youtube.com/embed/LDJNjQc9Zno?si=_a_OqvqPDZR-_jVO'; 
      case 'TODO RIESGO DE ELECTRÓNICOS':
        return 'https://www.youtube.com/embed/kBbvJfAKNJ4?si=4FJqHc0anW9ub6z7'; 
      case 'TODO RIESGO BICICLETAS':
        return 'https://www.youtube.com/embed/kH_1zyFUCUo?si=HFdalG_49rd0R8b2';
      case 'INSTRUMENTOS MUSICALES':
        return 'https://www.youtube.com/embed/wK4jS6QT8po?si=y5E-f_jAArGbf_uS';
      case 'ALQUILERES TEMPORARIOS':
        return 'https://www.youtube.com/embed/bzh4w8KKoEo?si=p5vaPHXpbCozMer5';
      default:
        return ''; // URL por defecto o vacía si no se encuentra un título coincidente
    }
  };
    
  const URLVideo = getVideoUrl();

  function obtenerVigencia(tituloasesoriaplus) {
    let vigenciaComun = (
      <div className='contenedor-vigencia'>
        <div className='vigencia-titulo'>
          VIGENCIA            
          <div className='vigencia-texto'>
            Anual
          </div>
        </div>
        <div className='vigencia-titulo'>
          PLAN DE PAGO            
          <div className='vigencia-texto'>
            12 pagos
          </div>
        </div>
        <div className='vigencia-titulo'>
          MONEDA            
          <div className='vigencia-texto'>
            Pesos
          </div>
        </div>
      </div>
    );
  
    switch (tituloasesoriaplus) {
      case 'INTEGRAL FOOD TRUCK':
      case 'TODO RIESGO DE ELECTRÓNICOS':
      case 'TODO RIESGO BICICLETAS':
      case 'ALQUILERES TEMPORARIOS':
        return (
          <>
            {vigenciaComun}
            <div className='vigencia-titulo'>
              AMBITO DE COBERTURA            
              <div className='vigencia-texto'>
                República Argentina
              </div>
            </div>
          </>
        );
      case 'INSTRUMENTOS MUSICALES':
        return vigenciaComun;
      default:
        return null;
    }
  }
  
  const vigencia = obtenerVigencia(tituloasesoriaplus);

  function obtenerPoliza(tituloasesoriaplus) {
    const polizas = {
      'TODO RIESGO BICICLETAS': [
        { titulo: 'POLIZA INDIVIDUAL', texto: 'Renovación Anual' },
        { titulo: '2 EVENTOS POR VIGENCIA DE POLIZA', texto: 'Hasta agotar la suma asegurada', videoLink: 'https://youtu.be/8gu0f-RBCUw?si=2L0LdDIEx15AgQQs' },
        { titulo: 'MEDIDA DE LA PRESTACIÓN', texto: 'A primer riesgo absoluto', videoLink: 'https://youtu.be/3rn178EK_CM?si=TYkDTr0w8nPj7iBV' },
        { titulo: 'FRANQUICIA', texto: 'SI, 20%', videoLink: 'https://youtu.be/kUDIZDmChLg?si=xxn1Eg1J9j8dNAeC' }
      ],
      'ALQUILERES TEMPORARIOS': [
        { titulo: '2 EVENTOS POR VIGENCIA DE POLIZA', texto: 'Hasta agotar la suma asegurada', videoLink: 'https://youtu.be/8gu0f-RBCUw?si=2L0LdDIEx15AgQQs' },
        { titulo: 'MEDIDA DE LA PRESTACIÓN', texto: 'A primer riesgo absoluto', videoLink: 'https://youtu.be/3rn178EK_CM?si=TYkDTr0w8nPj7iBV' },
        { titulo: 'FRANQUICIA', texto: 'SI, 20%', videoLink: 'https://youtu.be/kUDIZDmChLg?si=xxn1Eg1J9j8dNAeC' }
      ],
      // Agregar más casos según sea necesario
    };
  
    const poliza = polizas[tituloasesoriaplus];
  
    if (poliza) {
      return (
        <div className='contenedor-poliza'>
          {poliza.map((item, index) => (
            <div className='poliza-titulo' key={index}>
              {item.titulo}
              <div className='poliza-texto'>
                {item.texto}
                {item.videoLink && (
                  <a href={item.videoLink} target="_blank" rel="noopener noreferrer">(Video link)</a>
                )}
              </div>
            </div>
          ))}
        </div>
      );
    } else {
      return null;
    }
  }

const poliza = obtenerPoliza(tituloasesoriaplus);

  return (
    <div>
      <div className='contenedor-AP'>
        {/* Contenedor del video */}
        <div className="contenedor-video-AP">
          <iframe
            width="85%"
            height="100%"
            src={URLVideo}
            title="Video YouTube"
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>

        {/* Contenedor de texto */}
        <div className="contenedor-texto-AP">
          <div className='titulo-AP'>
            {tituloasesoriaplus}
          </div>
          <div>
            <TextosAsesoriaPlus tituloasesoriaplus={tituloasesoriaplus} />
          </div>      
          <div >
          {parseInt(idTipoSeguro) === 10 || parseInt(idTipoSeguro) === 12 || parseInt(idTipoSeguro) === 13 ? (
            <div>
              <ListaTipoPlan 
                handleClick={handleClick}
                setBodyCotizacion={setBodyCotizacion}
                idTipoSeguro={idTipoSeguro}
                EmpCod={EmpCod}
                ProTipCod={ProTipCod}
                TabCod={TabCod}
                TabCodCel={TabCodCel}
                AseCod={AseCod}
              />
            </div>
          ) : null}
          {(tituloasesoriaplus) ===  'TODO RIESGO BICICLETAS' ? (
                          <div className='subtitulo-AP'> 
                          CONDICIONES DE PÓLIZA:
                        </div>
          ) : null}
            {poliza}
            {vigencia}
          </div>
          <div className='centrar-AP'>
          <button className="btn-cotizar-AP" onClick={Cotizador}>
            COTIZAR
          </button>
          </div>
        </div>
      </div>
    </div>
  );
};


export default AsesoriaPlusDetalle;