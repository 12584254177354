import React, { useState } from 'react';
import config from '../config';
import '../stylesheets/PopUpLogin.css';
import '../stylesheets/MiCuenta.css';
import cerrar from '../media/x.png';

function PopUpCotizacion({ handleClose, codCot }) {
  const [showIframe, setShowIframe] = useState(false);

  const handleRegistrar = () => {
    window.open('/#/registrarse', '_blank');
    window.scrollTo(0, 0);
  };

  return (
    <div className="popup-login-contenedor">
        {!showIframe ? (
          <div className="popup-cotizacion">
            <div className="opciones-popup-cotizacion">
              <a onClick={() => setShowIframe(true)}>INGRESAR</a>
              <p>¿Todavía no tenés cuenta?</p>
              <a onClick={handleRegistrar}> <b>REGISTRATE</b> </a>
            </div>
            <div className="cerrarPopUp-btn" onClick={handleClose}>
              <img src={cerrar} alt="Ícono de Cierre" />
            </div>
          </div>
        ) : (
          <div className="popup-login">
            <div className="iframe-container">
              <iframe
                className='iframe-mi-cuenta'
                title="Backoffice"
                src={`${config.compraUrl}?1,2,${codCot},4`}
                width="750vw"
                height="600vh"
                frameBorder="0"
                scrolling="auto"
              ></iframe>
            </div>
            <div className="cerrarPopUp-btn" onClick={handleClose}>
              <img src={cerrar} alt="Ícono de Cierre" />
            </div>
          </div>
        )}
      
    </div>
  );
}

export default PopUpCotizacion;
