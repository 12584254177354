import React, {useState, useEffect} from 'react';
import BotonCotizar from './BotonCotizar';
import Encabezado from './Encabezado';
import PieDePagina from './PieDePagina';
import asesoria1 from '../media/asesoriaplus2.svg';
import asesoria2 from '../media/asesoriaplus1.svg';
import '../stylesheets/HeaderPasos.css';
import '../stylesheets/Inicio.css';
import '../stylesheets/BotonCotizar.css';
import Cotizador from './Cotizador';
import AnimatedGif from './AnimatedGif';
import MarcosSeccion from './MarcosSeccion';
import NegocioSeccion from './NegocioSeccion';
import AsociacionesSeccion from './AsociacionesSeccion';
import puntos from '../media/puntos.svg';
import { useNavigate } from 'react-router-dom';
import { useScreen } from './ScreenContext';


const BotonAsesoria = () => {
  const [hovered, setHovered] = useState(false);
  const { setScreen } = useScreen(); 
  const navigate = useNavigate();

  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseOut = () => {
    setHovered(false);
  };

  const asesoriaPlus = () => {
    setScreen('Asesoria');
    navigate('/asesoriaplus');
  };

  return (
    <button
      className="btn-asesoria"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onClick={asesoriaPlus}
    >
     <img 
      src={hovered ? asesoria1 : asesoria2}
      alt='ImagenAsesoriaPlus'
      />
    </button>
  );

};

function Inicio () {
  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768); // Definir el ancho mínimo para considerar como escritorio
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Llamar a la función inicialmente para establecer el estado correcto
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

    return (
      <div>
        <Encabezado></Encabezado>
        <div className="Inicio" >
            <div className='contenedor-img-puntos-texto'>
              <div className='contenedor-imagen-puntos-inicio'>
                <img 
                  src={puntos}
                  className='imagen-puntos'
                />
              </div>
              <div className='main-container'>
                  <div className='contenedor-mobile '>
                    <p className="titulo-inicio">CUIDAMOS LO QUE TE IMPORTA</p>
                    <h2 className="titulo2-inicio">ASEGURÁ RÁPIDO Y FÁCIL</h2>
                  </div>
                  <BotonCotizar> </BotonCotizar>
                  <div className='centrar'>
                    <BotonAsesoria></BotonAsesoria>
                  </div>  
              </div>
              <div className='gif-container'>
                    <AnimatedGif 
                        src={require('../media/Personajes--Home-2_1-sin-halo.gif')}
                        alt='Animated GIF'
                      />
                  </div>
            </div>
              <div className='cotizador-container'>
                 <Cotizador></Cotizador>
              </div>
              <div className='marcos-seccion-container'>
                  <MarcosSeccion></MarcosSeccion>
              </div>
              <div className='negocio-seccion-container'>
                  <NegocioSeccion></NegocioSeccion>
              </div>
              <div className='asociaciones-seccion-container'>
                  <AsociacionesSeccion></AsociacionesSeccion>
              </div>
        </div>
        <div className='pieDePagina-container'>
            <PieDePagina></PieDePagina>
        </div>
    </div> 
    );
  };
  
  export default Inicio;