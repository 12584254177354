import React from 'react';
import '../stylesheets/PopUp.css';
import logo from '../media/Logo_multiseguria.png'
import AsesoriaPlusPlanes from './AsesoriaPlusPlanes';
import cerrar from '../media/x.png';

function PopUp({ handleClose, txtPopUp }) {
  return (
    <div className="popup-contenedor">
      <div className="popup">
        <AsesoriaPlusPlanes tipoAlquiler={txtPopUp}/>
        <img src={logo} alt="Imagen" className="popup-image" />
        <div className="cerrarPopUp-btn" onClick={handleClose}>
            <img src={cerrar} alt="Ícono de Cierre" />
          </div>
      </div>
    </div>
  );
}

export default PopUp;
