import React, { useState } from 'react';
import '../stylesheets/FormularioMundoRenta.css';
 

function AsesoriaPlusPlanes({ tipoAlquiler }) {

  // Función para obtener la ruta de la imagen según plan
  const getTextoPlanAP = (tipoAlquiler) => {
        switch (tipoAlquiler) {
          case 'BicicletaA':
            return (
                <div>
                    <div className='titulo-plan-MR'>
                        BICICLETA PLAN A 
                    </div>
                    <div className='descripcion-plan-MR'>
                        <p>VALOR BICICLETA - ROBO DAÑOS____________<b>$90.000</b></p>
                        <p>EFECTOS PERSONALES______________________<b>$10.000</b></p>
                        <p>RESPONSABILIDAD CIVIL____________________<b>$60.000</b></p>
                        <p>AP-MUERTE_______________________________<b>$200.000</b></p>
                        <p>AP-INV.PERMANENTE TOTAL/PARCI_________<b>$200.000</b></p>
                        <p>AP-FRACTURA HUESOS____________________<b>$300.000</b></p>
                        <div className='titulo-plan-MR'>
                            Precio Mensual: &nbsp;                         $1.498,00
                        </div>
                    </div>
                </div>
            );
          case 'BicicletaB':
            return (
                <div>
                    <div className='titulo-plan-MR'>
                        BICICLETA PLAN B
                    </div>
                    <div className='descripcion-plan-MR'>
                        <p>VALOR BICICLETA - ROBO DAÑOS___________<b>$135.000</b></p>
                        <p>EFECTOS PERSONALES______________________<b>$10.000</b></p>
                        <p>RESPONSABILIDAD CIVIL___________________<b>$200.000</b></p>
                        <p>AP-MUERTE_______________________________<b>$200.000</b></p>
                        <p>AP-INV.PERMANENTE TOTAL/PARCI_________<b>$200.000</b></p>
                        <p>AP-FRACTURA HUESOS_____________________<b>$15.000</b></p>
                        <div className='titulo-plan-MR'>
                            Precio Mensual: &nbsp;                         $2.702,00
                        </div>
                    </div>
                </div>
            );
          case 'BicicletaC':
            return (
                <div>
                    <div className='titulo-plan-MR'>
                        BICICLETA PLAN C
                    </div>
                    <div className='descripcion-plan-MR'>
                        <p>VALOR BICICLETA - ROBO DAÑOS___________<b>$180.000</b></p>
                        <p>EFECTOS PERSONALES______________________<b>$10.000</b></p>
                        <p>RESPONSABILIDAD CIVIL___________________<b>$200.000</b></p>
                        <p>AP-MUERTE_______________________________<b>$200.000</b></p>
                        <p>AP-INV.PERMANENTE TOTAL/PARCI_________<b>$200.000</b></p>
                        <p>AP-FRACTURA HUESOS______________________<b>$25.000</b></p>
                        <div className='titulo-plan-MR'>
                            Precio Mensual: &nbsp;                         $3.952,00
                        </div>
                    </div>
                </div>
            );
          case 'BicicletaD':
            return (
                <div>
                    <div className='titulo-plan-MR'>
                        BICICLETA PLAN D
                    </div>
                    <div className='descripcion-plan-MR'>
                        <p>VALOR BICICLETA - ROBO DAÑOS___________<b>$225.000</b></p>
                        <p>EFECTOS PERSONALES______________________<b>$10.000</b></p>
                        <p>RESPONSABILIDAD CIVIL___________________<b>$200.000</b></p>
                        <p>AP-MUERTE_______________________________<b>$200.000</b></p>
                        <p>AP-INV.PERMANENTE TOTAL/PARCI_________<b>$200.000</b></p>
                        <p>AP-FRACTURA HUESOS______________________<b>$25.000</b></p>
                        <div className='titulo-plan-MR'>
                            Precio Mensual: &nbsp;                         $5.202,00
                        </div>
                    </div>
                </div>
            );
          case 'BicicletaE':
            return (
                <div>
                    <div className='titulo-plan-MR'>
                        BICICLETA PLAN E
                    </div>
                    <div className='descripcion-plan-MR'>
                        <p>VALOR BICICLETA - ROBO DAÑOS___________<b>$270.000</b></p>
                        <p>EFECTOS PERSONALES______________________<b>$10.000</b></p>
                        <p>RESPONSABILIDAD CIVIL___________________<b>$200.000</b></p>
                        <p>AP-MUERTE_______________________________<b>$200.000</b></p>
                        <p>AP-INV.PERMANENTE TOTAL/PARCI_________<b>$200.000</b></p>
                        <p>AP-FRACTURA HUESOS______________________<b>$25.000</b></p>
                        <div className='titulo-plan-MR'>
                            Precio Mensual: &nbsp;                         $6.521,00
                        </div>
                    </div>
                </div>
            );
          case 'FoodTruck1':
            return (
                <div>
                    <div className='titulo-plan-MR'>
                        FOOD TRUCK PLAN 1
                    </div>
                    <div className='descripcion-plan-MR'>
                        <p>INCENDIO DEL CONTENIDO______________________<b>$500.000</b></p>
                        <p>ROBO DEL CONTENIDO__________________________<b>$100.000</b></p>
                        <p>VALORES CAJÓN MOSTRADOR____________________<b>$10.000</b></p>
                        <p>SEGURO TÉCNICO (PC,NOTEBOOK)________________<b>$50.000</b></p>
                        <p>CRISTALES________________________________________<b>$5.000</b></p>
                        <p>RES. CIVIL COMPRENSIVA________________________<b>$600.000</b></p>
                        <div className='titulo-plan-MR'>
                            Precio Mensual: <span style={{ marginRight: '100px' }}></span> $1.130,00
                        </div>
                    </div>
                </div>
            );
          case 'FoodTruck2':
            return (
                <div>
                    <div className='titulo-plan-MR'>
                        FOOD TRUCK PLAN 2
                    </div>
                    <div className='descripcion-plan-MR'>
                        <p>INCENDIO DEL CONTENIDO______________________<b>$750.000</b></p>
                        <p>ROBO DEL CONTENIDO__________________________<b>$150.000</b></p>
                        <p>VALORES CAJÓN MOSTRADOR____________________<b>$15.000</b></p>
                        <p>SEGURO TÉCNICO (PC,NOTEBOOK)________________<b>$100.000</b></p>
                        <p>CRISTALES________________________________________<b>$7.500</b></p>
                        <p>RES. CIVIL COMPRENSIVA________________________<b>$800.000</b></p>
                        <div className='titulo-plan-MR'>
                            Precio Mensual: <span style={{ marginRight: '100px' }}></span> $1.320,00
                        </div>
                    </div>
                </div>
            );
          case 'FoodTruck3':
            return (
                <div>
                    <div className='titulo-plan-MR'>
                        FOOD TRUCK PLAN 3
                    </div>
                    <div className='descripcion-plan-MR'>
                        <p>INCENDIO DEL CONTENIDO______________________<b>$1.000.000</b></p>
                        <p>ROBO DEL CONTENIDO___________________________<b>$200.000</b></p>
                        <p>VALORES CAJÓN MOSTRADOR_____________________<b>$20.000</b></p>
                        <p>SEGURO TÉCNICO (PC,NOTEBOOK)________________<b>$150.000</b></p>
                        <p>CRISTALES_______________________________________<b>$10.000</b></p>
                        <p>RES. CIVIL COMPRENSIVA_______________________<b>$1.000.000</b></p>
                        <div className='titulo-plan-MR'>
                            Precio Mensual: <span style={{ marginRight: '100px' }}></span> $1.630,00
                        </div>
                    </div>
                </div>
            );
          case 'AlquilerA':
            return (
                <div>
                    <div className='titulo-plan-MR'>
                        ALQUILER TURÍSTICO PLAN A
                    </div>
                    <div className='descripcion-plan-MR'>
                        <p>INCENDIO DE EDIFICIO_____________________<b>$10.000.000</b></p>
                        <p>INCENDIO DE CONTENIDO____________________<b>$600.000</b></p>
                        <p>ROBO GENERAL_______________________________<b>$60.000</b></p>
                        <p>TODO RIESGO ELECTRÓNICO__________________<b>$120.000</b></p>
                        <p>CRISTALES____________________________________<b>$30.000</b></p>
                        <p>RESPONSABILIDAD CIVIL______________________<b>$300.000</b></p>
                        <div className='titulo-plan-MR'>
                            Valor Mensual: <span style={{ marginRight: '100px' }}></span> $2.625,00
                        </div>
                    </div>
                </div>
            );
          case 'AlquilerB':
            return (
                <div>
                    <div className='titulo-plan-MR'>
                        ALQUILER TURÍSTICO PLAN B
                    </div>
                    <div className='descripcion-plan-MR'>
                        <p>INCENDIO DE EDIFICIO____________________<b>$27.000.000</b></p>
                        <p>INCENDIO DE CONTENIDO__________________<b>$1.200.000</b></p>
                        <p>ROBO GENERAL______________________________<b>$90.000</b></p>
                        <p>TODO RIESGO ELECTRÓNICO_________________<b>$180.000</b></p>
                        <p>CRISTALES___________________________________<b>$60.000</b></p>
                        <p>RESPONSABILIDAD CIVIL___________________<b>$6.000.000</b></p>
                        <div className='titulo-plan-MR'>
                            Valor Mensual: <span style={{ marginRight: '100px' }}></span> $5.774,00
                        </div>
                    </div>
                </div>
            );
          case 'AlquilerC':
            return (
                <div>
                    <div className='titulo-plan-MR'>
                        ALQUILER TURÍSTICO PLAN C
                    </div>
                    <div className='descripcion-plan-MR'>
                        <p>INCENDIO DE EDIFICIO_____________________<b>$45.000.000</b></p>
                        <p>INCENDIO DE CONTENIDO___________________<b>$1.800.000</b></p>
                        <p>ROBO GENERAL______________________________<b>$120.000</b></p>
                        <p>TODO RIESGO ELECTRÓNICO__________________<b>$240.000</b></p>
                        <p>CRISTALES____________________________________<b>$90.000</b></p>
                        <p>RESPONSABILIDAD CIVIL____________________<b>$8.000.000</b></p>
                        <div className='titulo-plan-MR'>
                            Valor Mensual: <span style={{ marginRight: '100px' }}></span> $8.841,00
                        </div>
                    </div>
                </div>
            );
          case 'AlquilerD':
            return (
                <div>
                    <div className='titulo-plan-MR'>
                        ALQUILER TURÍSTICO PLAN D
                    </div>
                    <div className='descripcion-plan-MR'>
                        <p>INCENDIO DE EDIFICIO_____________________<b>$62.000.000</b></p>
                        <p>INCENDIO DE CONTENIDO___________________<b>$2.400.000</b></p>
                        <p>ROBO GENERAL______________________________<b>$150.000</b></p>
                        <p>TODO RIESGO ELECTRÓNICO__________________<b>$300.000</b></p>
                        <p>CRISTALES___________________________________<b>$120.000</b></p>
                        <p>RESPONSABILIDAD CIVIL___________________<b>$10.000.000</b></p>
                        <div className='titulo-plan-MR'>
                            Valor Mensual: <span style={{ marginRight: '100px' }}></span> $11.792,00
                        </div>
                    </div>
                </div>
            );
          case 'AlquilerE':
            return (
                <div>
                    <div className='titulo-plan-MR'>
                        ALQUILER TURÍSTICO PLAN E
                    </div>
                    <div className='descripcion-plan-MR'>
                        <p>INCENDIO DE EDIFICIO_____________________<b>$80.000.000</b></p>
                        <p>INCENDIO DE CONTENIDO__________________<b>$3.000.000</b></p>
                        <p>ROBO GENERAL______________________________<b>$180.000</b></p>
                        <p>TODO RIESGO ELECTRÓNICO__________________<b>$360.000</b></p>
                        <p>CRISTALES___________________________________<b>$150.000</b></p>
                        <p>RESPONSABILIDAD CIVIL___________________<b>$12.000.000</b></p>
                        <div className='titulo-plan-MR'>
                            Valor Mensual: <span style={{ marginRight: '100px' }}></span> $14.859,00
                        </div>
                    </div>
                </div>
            );
          default:
        }
    }

            
    // Obtener texto
    const textoAP = getTextoPlanAP(tipoAlquiler);

  return (
    <div>
         {textoAP}
    </div>
  )
};

export default AsesoriaPlusPlanes;
